<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div>
              <vs-switch v-model="vSwitch">
                <template #on>
                    電腦版
                </template>
                <template #off>
                    手機版
                </template>
              </vs-switch>
            </div>
            <div>
              <editor v-if='vSwitch' ref="editor" v-model="content1" :content="content1"></editor>
              <editor v-else ref="editor" v-model="content2" :content="content2"></editor>
            </div>
            <div class="flex justify-between">
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
          </ValidationObserver>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import editor from '@/components/editor.vue'

export default {
  name: 'landlord',
  components: {
    editor
  },
  data() {
    return {
      vSwitch: true,
      content1: '',
      content2: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: `backstage/homes/landlord`,
        method: 'get',
      }).then((res) => {
        if (res.data.status) {
          this.is_API_loaded = true
          this.content1 = res.data.data.content1
          this.content2 = res.data.data.content2
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
        this.saveSubmit(`backstage/homes/landlord`, 'put', invalid);
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      // console.log(this.$data)
      this.$axios({
        url: url,
        method: method,
        data: {
          content1: this.content1,
          content2: this.content2,
        },
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
  span{
    color: #FF0000;
  }
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}
</style>

